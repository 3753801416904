// NOTE 메인 분석 컴포넌트
export const analyzeData = (entireOrganData, memberData) => {
  if (!entireOrganData || !memberData) return null;

  const totalOrganCount = entireOrganData.length;
  const totalMemberCount = memberData.length;

  const currentDate = new Date();

  const memberGrowth = memberData.reduce((acc, member) => {
    const date = new Date(member.mem_register_datetime);
    const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  const organGrowth = entireOrganData.reduce((acc, organ) => {
    const date = new Date(organ.start);
    const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  // NOTE 이탈 멤버 계산
  const memberChurn = {};
  const paidMemberChurn = {};
  const paidMemberGrowth = {};
  memberData.forEach(member => {
    const lastLogin = new Date(member.mem_lastlogin_datetime);
    const monthsSinceLastLogin = (currentDate - lastLogin) / (30 * 24 * 60 * 60 * 1000);
    
    if (monthsSinceLastLogin > 1) {
      const churnDate = new Date(lastLogin.getFullYear(), lastLogin.getMonth() + 1, 1);
      const churnMonthYear = `${churnDate.getFullYear()}-${String(churnDate.getMonth() + 1).padStart(2, '0')}`;
      memberChurn[churnMonthYear] = (memberChurn[churnMonthYear] || 0) + 1;
      
      // 누적 이탈 유료회원수
      if (member.mem_a_club === 1) {
        paidMemberChurn[churnMonthYear] = (paidMemberChurn[churnMonthYear] || 0) + 1;
      }
    }

    const expirationDate = new Date(member.mem_expiration_date);
    if (expirationDate >= currentDate) {
      const registrationDate = new Date(member.mem_register_datetime);
      const paidMonthYear = `${registrationDate.getFullYear()}-${String(registrationDate.getMonth() + 1).padStart(2, '0')}`;
      paidMemberGrowth[paidMonthYear] = (paidMemberGrowth[paidMonthYear] || 0) + 1;
    }
  });

  const allDates = [...new Set([...Object.keys(memberGrowth), ...Object.keys(organGrowth), ...Object.keys(memberChurn), ...Object.keys(paidMemberGrowth), ...Object.keys(paidMemberChurn)])].sort();
  
  let cumulativeMemberCount = 0;
  let cumulativeOrganCount = 0;
  let cumulativeChurnCount = 0;
  let cumulativePaidMemberCount = 0;
  let cumulativePaidChurnCount = 0;
  
  const chartData = allDates.map(date => {
    cumulativeMemberCount += memberGrowth[date] || 0;
    cumulativeOrganCount += organGrowth[date] || 0;
    cumulativeChurnCount += memberChurn[date] || 0;
    cumulativePaidMemberCount += paidMemberGrowth[date] || 0;
    cumulativePaidChurnCount += paidMemberChurn[date] || 0;
    const activeMemberCount = cumulativeMemberCount - cumulativeChurnCount;
    const marketShare = Math.min((activeMemberCount / cumulativeOrganCount * 100), 25).toFixed(2);
    const cumulativeChurnRate = (cumulativeChurnCount / cumulativeMemberCount * 100).toFixed(2);
    const cumulativePaidChurnRate = (cumulativePaidChurnCount / cumulativePaidMemberCount * 100).toFixed(2);

    return {
      date,
      cumulativeMemberCount: activeMemberCount,
      cumulativeOrganCount,
      cumulativeChurnCount,
      cumulativePaidChurnCount,
      cumulativePaidMemberCount,
      cumulativeChurnRate: parseFloat(cumulativeChurnRate),
      cumulativePaidChurnRate: parseFloat(cumulativePaidChurnRate),
      marketShare
    };
  });

  const activeMemberCount = cumulativeMemberCount - cumulativeChurnCount;
  const overallChurnRate = (cumulativeChurnCount / totalMemberCount * 100).toFixed(2);
  const currentPaidMemberCount = cumulativePaidMemberCount;
  const overallPaidChurnRate = (cumulativePaidChurnCount / cumulativePaidMemberCount * 100).toFixed(2);

  return {
    totalOrganCount,
    totalMemberCount,
    activeMemberCount,
    currentPaidMemberCount,
    overallChurnRate: parseFloat(overallChurnRate),
    overallPaidChurnRate: parseFloat(overallPaidChurnRate),
    chartData
  };
};

// NOTE 매출액 계산
export const calculateRevenue = (memberData, fee, cost, customMemberIncrease) => {
  const currentDate = new Date();
  const activeMembers = memberData.filter(member => new Date(member.mem_expiration_date) >= currentDate);

  const monthlyData = activeMembers.reduce((acc, member) => {
    const date = new Date(member.mem_register_datetime);
    const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    if (!acc[monthYear]) {
      acc[monthYear] = { members: 0 };
    }
    acc[monthYear].members++;
    return acc;
  }, {});

  let cumulativeMembers = 0;
  let yearlyRevenue = {};
  let yearlyProfit = {};
  const revenueChartData = Object.entries(monthlyData)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([date, data]) => {
      cumulativeMembers += data.members;
      const revenue = cumulativeMembers * fee;
      const totalCost = cumulativeMembers * cost;
      const profit = revenue - totalCost;
      const year = date.split('-')[0];
      
      yearlyRevenue[year] = (yearlyRevenue[year] || 0) + revenue;
      yearlyProfit[year] = (yearlyProfit[year] || 0) + profit;

      return {
        date,
        revenue,
        profit,
        cumulativeMembers
      };
    });

  // NOTE 년도별 누적 데이터 계산 (예상치 포함)
  const currentYear = new Date().getFullYear().toString();
  const yearlyData = Object.keys(yearlyRevenue).map(year => {
    const yearData = {
      year,
      revenue: yearlyRevenue[year],
      profit: yearlyProfit[year]
    };

    if (year === currentYear) {
      const currentYearData = revenueChartData.filter(data => data.date.startsWith(currentYear));
      const lastMonth = currentYearData[currentYearData.length - 1];
      const lastMonthDate = new Date(lastMonth.date);
      const monthsInYear = 12;
      const monthsPassed = lastMonthDate.getMonth() + 1;
      const remainingMonths = monthsInYear - monthsPassed;

      let projectedNewMembers;
      if (customMemberIncrease && !isNaN(Number(customMemberIncrease))) {
        projectedNewMembers = Number(customMemberIncrease);
      } else {
        const lastMonthMemberIncrease = lastMonth.cumulativeMembers - (currentYearData[currentYearData.length - 2]?.cumulativeMembers || 0);
        projectedNewMembers = lastMonthMemberIncrease * remainingMonths;
      }

      yearData.projectedRevenue = yearData.revenue + (fee * projectedNewMembers * remainingMonths);
      yearData.projectedProfit = yearData.profit + ((fee - cost) * projectedNewMembers * remainingMonths);
    }

    return yearData;
  });

  return { revenueChartData, yearlyData };
};

// NOTE 월별 증감율
export const calculateMonthlyGrowthRate = (memberData) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // 활성 회원 필터링 및 당월 이전 데이터만 사용
  const activeMembers = memberData.filter(member => {
    const expDate = new Date(member.mem_expiration_date);
    const regDate = new Date(member.mem_register_datetime);
    return expDate >= currentDate && 
           (regDate.getFullYear() < currentYear || 
           (regDate.getFullYear() === currentYear && regDate.getMonth() < currentMonth));
  });

  // 각 월별 가입자 수와 탈퇴자 수를 계산
  const monthlyData = activeMembers.reduce((acc, member) => {
    const joinDate = new Date(member.mem_register_datetime);
    const joinMonthYear = `${joinDate.getFullYear()}-${String(joinDate.getMonth() + 1).padStart(2, '0')}`;
    
    const expirationDate = new Date(member.mem_expiration_date);
    const expirationMonthYear = `${expirationDate.getFullYear()}-${String(expirationDate.getMonth() + 1).padStart(2, '0')}`;
    
    // 당월 이전 데이터만 처리
    if (joinDate < new Date(currentYear, currentMonth, 1)) {
      acc[joinMonthYear] = acc[joinMonthYear] || { join: 0, leave: 0 };
      acc[joinMonthYear].join += 1;
    }
    
    if (expirationDate < new Date(currentYear, currentMonth, 1) && expirationMonthYear !== joinMonthYear) {
      acc[expirationMonthYear] = acc[expirationMonthYear] || { join: 0, leave: 0 };
      acc[expirationMonthYear].leave += 1;
    }
    
    return acc;
  }, {});

  const allDates = Object.keys(monthlyData).sort();
  let cumulativeCount = 0;
  const monthlyGrowthRateData = allDates.map((date) => {
    const { join, leave } = monthlyData[date];
    const netGrowth = join - (leave || 0);
    cumulativeCount += netGrowth;

    return {
      month: date,
      growthNumber: netGrowth,
      joinCount: join,
      leaveCount: leave || 0,
      cumulativeCount: cumulativeCount
    };
  });

  return monthlyGrowthRateData;
};