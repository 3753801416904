import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatNumber } from '../../../../util/utils';

const YearlyRevenueChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="year" />
      <YAxis 
        tickFormatter={(value) => `${formatNumber(Math.round(value / 1000000))}M`}
        style={{ fontSize: '12px' }}
      />
      <Tooltip 
        formatter={(value, name) => {
          const formattedValue = formatNumber(value);
          switch(name) {
            case 'revenue': return [`${formattedValue}원`, '실제 매출'];
            case 'profit': return [`${formattedValue}원`, '실제 수익'];
            case 'projectedRevenue': return [`${formattedValue}원`, '예상 매출'];
            case 'projectedProfit': return [`${formattedValue}원`, '예상 수익'];
            default: return [formattedValue, name];
          }
        }}
        labelFormatter={(label) => `년도: ${label}`}
      />
      <Legend />
      <Bar dataKey="revenue" fill="#82ca9d" name="실제 매출" />
      <Bar dataKey="profit" fill="#8884d8" name="실제 수익" />
      <Bar dataKey="projectedRevenue" fill="#ffc658" name="예상 매출" />
      <Bar dataKey="projectedProfit" fill="#ff7300" name="예상 수익" />
    </BarChart>
  </ResponsiveContainer>
);

export default YearlyRevenueChart;
