import React, { useState, useEffect } from 'react';
import './Calculator.css'; // CSS 파일 임포트

const Calculator = () => {
    const [grade, setGrade] = useState(1);
    const [percent, setPercent] = useState(15);
    const [serviceTime, setServiceTime] = useState(3);
    const [serviceOptions, setServiceOptions] = useState([1, 2, 3, 4]);
    const [visitsPerWeek, setVisitsPerWeek] = useState(3);

    const grades = [1, 2, 3, 4, 5];
    const percents = [6, 9, 15];
    const visitsOptions = [3, 4, 5, 6];

    const charges = {
        "30": 16630,
        "60": 24120,
        "90": 32510,
        "120": 41380,
        "150": 48250,
        "180": 54320,
        "210": 60530,
        "240": 66770
    };

    const limits = {
        "1": 2069900,
        "2": 1869600,
        "3": 1455800,
        "4": 1341800,
        "5": 1151600
    };

    useEffect(() => {
        if (grade === 1 || grade === 2) {
            setServiceOptions([1, 2, 3, 4]);
            setServiceTime(3);
        } else if (grade === 3 || grade === 4) {
            setServiceOptions([1, 2, 3]);
            setServiceTime(2);
        } else {
            setServiceOptions([1, 2]);
            setServiceTime(1);
        }
    }, [grade]);

    const calculateCost = () => {
        const monthlyLimit = limits[grade];
        const serviceCharge = charges[serviceTime * 60]; // 서비스 시간에 따른 비용 계산
        const totalServiceCharge = serviceCharge * visitsPerWeek * 4;
        let userCharge = totalServiceCharge * (percent / 100);
        userCharge = Math.floor(userCharge / 10) * 10; // 원 단위 절사
        const governmentSupport = totalServiceCharge - userCharge;

        return { totalServiceCharge, userCharge, governmentSupport };
    };

    const { totalServiceCharge, userCharge, governmentSupport } = calculateCost();

    return (
        <div className="calc">
            <p className="title">예상 비용 계산</p>
            <select value={grade} onChange={(e) => setGrade(Number(e.target.value))}>
                {grades.map(g => (
                    <option key={g} value={g}>{g}등급 어르신</option>
                ))}
            </select>
            <select value={percent} onChange={(e) => setPercent(Number(e.target.value))}>
                {percents.map(p => (
                    <option key={p} value={p}>본인부담률({p}%)</option>
                ))}
            </select>
            <select value={serviceTime} onChange={(e) => setServiceTime(Number(e.target.value))}>
                {serviceOptions.map(time => (
                    <option key={time} value={time}>{time}시간</option>
                ))}
            </select>
            <select value={visitsPerWeek} onChange={(e) => setVisitsPerWeek(Number(e.target.value))}>
                {visitsOptions.map(visits => (
                    <option key={visits} value={visits}>주 {visits}회 방문서비스</option>
                ))}
            </select>
            {/* <div className="cost-info">
                <p>총 사용금액: 월<span className="user-charge"> {totalServiceCharge.toLocaleString()}</span>원</p>
                <p>개인 부담금: 월<span className="user-charge"> {userCharge.toLocaleString()}</span>원</p>
                <p>국가 지원금: 월<span className="government-support"> {governmentSupport.toLocaleString()}</span>원</p>
            </div> */}
            <div className="amount">
                <div className="df__">
                    <p>개인 부담금</p>
                    <p>월 <span>{userCharge.toLocaleString()}</span>원</p>
                </div>
                <div className="df__">
                    <p>국가 지원금</p>
                    <p>월 <span>{governmentSupport.toLocaleString()}</span>원</p>
                </div>
            </div>
        </div>
    );
};

export default Calculator;