import React from 'react';

const SubMenu = ({ activeMenu, activeSubMenu, setActiveSubMenu }) => {
  return (
    <div className="flex flex-col space-y-4 p-4 bg-gray-200 h-full">
      {activeMenu === '운영관리' ? (
        <>
          <button
            className={`px-3 py-2 rounded shadow ${activeSubMenu === '개발' ? 'bg-blue-500 text-white' : 'bg-white'}`}
            onClick={() => setActiveSubMenu('개발')}
          >
            개발
          </button>
          <button
            className={`px-3 py-2 rounded shadow ${activeSubMenu === '상담' ? 'bg-blue-500 text-white' : 'bg-white'}`}
            onClick={() => setActiveSubMenu('상담')}
          >
            상담
          </button>
        </>
      ) : (
        <>
          <button
            className={`px-3 py-2 rounded shadow ${activeSubMenu === '매출분석' ? 'bg-blue-500 text-white' : 'bg-white'}`}
            onClick={() => setActiveSubMenu('매출분석')}
          >
            매출분석
          </button>
          <button
            className={`px-3 py-2 rounded shadow ${activeSubMenu === '결제관리' ? 'bg-blue-500 text-white' : 'bg-white'}`}
            onClick={() => setActiveSubMenu('결제관리')}
          >
            결제관리
          </button>
        </>
      )}
    </div>
  );
};

export default SubMenu;