
import React from 'react';
import './LayoutFooter.css';

const Footer = () => {
    return (
        <footer className="footer-wp">
            <div className="footer-content">
                <p>
                    <span>대표 : 김백년 | </span>
                    <span>사업자 등록번호 : 569-01-01785 | </span>
                    <span>통신판매신고번호 : 2020-대구달성-0486</span>
                </p>
                <p>
                    <span>상호명 : 실버시트 | </span>
                    <span>주소 : 대구광역시 달서구 와룡로 11, 2층 | </span>
                    <span>E-mail : info@silversheet.co.kr</span>
                </p>

                <p style={{ margin: "30px 0", }}><b>Copyright ©실버시트.All Rights Reserved.</b></p>

                <p>본 사이트는 노인장기요양보험 유관 부처의 활용 확인을 받아 데이터를 수집하고 있습니다.</p>
                <p>수집한 정보는 시설을 찾는 사용자에게 무료로 제공중이며, 노인장기요양 홈페이지에서 자세한 정보를 확인할 수 있고,</p>
                <p>기관 정보는 공공데이터포털과 노인장기요양보험페이지의 사정에 따라 확인 시점과 차이날 수 있습니다.</p>
                <p>비공개 처리를 원하시는 시설/기관장님이나 수급자 및 가족분들은 tvfk@silverbook.co.kr 로 연락 주시기 바랍니다.</p>
                <p>사진 및 프로그램과 시설/기관 정보 등은 해당 기관의 데이터로 정보의 권리는 모두 시설/기관에게 있습니다.</p>
            </div>
        </footer>
    );
};

export default Footer;