import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// jQuery와 Bootstrap CSS 및 JavaScript를 로드하기 위해 스크립트와 링크를 동적으로 추가
const addCDNScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
};

const addCDNLink = (href) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
};

// 네이버 클라우드 지도 API
addCDNScript('https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=slonb1eqmk&submodules=geocoder');
// jQuery CDN
addCDNScript('https://code.jquery.com/jquery-3.6.0.min.js');
// font-awesome CDN
addCDNLink('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
addCDNLink('https://hangeul.pstatic.net/hangeul_static/css/nanum-square-round.css');
// Tailwind CSS CDN
addCDNLink('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <meta name="naver-site-verification" content="9837d29e941c0983fb3c6ffa3193a859efbd8439" />
            </Helmet>
            <App />
        </HelmetProvider>
    </React.StrictMode>
);

reportWebVitals();