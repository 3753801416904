import React from 'react';

const TopNav = ({ activeMenu, setActiveMenu }) => {
  return (
      <nav className="flex flex-col space-y-4 p-4 bg-gray-800 text-white h-full">
          <button
              className={`px-3 py-2 rounded ${activeMenu === '운영관리' ? 'bg-blue-500' : ''}`}
              onClick={() => setActiveMenu('운영관리')}
          >
              운영관리
          </button>
          <button
              className={`px-3 py-2 rounded ${activeMenu === '매출관리' ? 'bg-blue-500' : ''}`}
              onClick={() => setActiveMenu('매출관리')}
          >
              매출관리
          </button>
          {/* <button className="mt-auto px-3 py-2 bg-green-500 rounded">로그인</button> */}
      </nav>
  );
};

export default TopNav;