import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CumulativeGrowthChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis yAxisId="left" />
      <YAxis yAxisId="right" orientation="right" />
      <Tooltip />
      <Legend />
      <Line yAxisId="left" type="monotone" dataKey="cumulativeOrganCount" stroke="#82ca9d" name="누적 기관 수" />
      <Line yAxisId="right" type="monotone" dataKey="cumulativeMemberCount" stroke="#8884d8" name="누적 활성 회원 수" />
      <Line yAxisId="right" type="monotone" dataKey="cumulativeChurnCount" stroke="#ff7300" name="누적 이탈 회원 수" />
      <Line yAxisId="right" type="monotone" dataKey="cumulativePaidChurnCount" stroke="#ff1493" name="누적 유료 이탈 회원 수" />
      <Line yAxisId="right" type="monotone" dataKey="cumulativePaidMemberCount" stroke="#0088FE" name="누적 유료 회원 수" />
    </LineChart>
  </ResponsiveContainer>
);

export default CumulativeGrowthChart;