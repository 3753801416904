// 네이버 지도 그리는 부분
/**
 * 
 * @param {*} mapElementId 기본값 그냥 'map'
 * @param {*} lat 위도
 * @param {*} lng 경도
 * @returns 
 */
export const initializeNaverMap = (mapElementId, lat, lng) => {
    if (window.naver && window.naver.maps) {
      const mapOptions = {
        center: new window.naver.maps.LatLng(lat, lng),
        zoom: 15
      };
      
      const map = new window.naver.maps.Map(mapElementId, mapOptions);
  
      new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(lat, lng),
        map: map
      });
  
      return map;
    }
    return null;
};