import React from 'react';
import './LayoutHeader.css';

import logo from '../../assets/images/logo.png';

const LayoutHeader = () => {
    return (
        <header className="header-wp">
            <a href="/">
                <img className="logo" src={logo} alt="Logo" />
                {/* <div className="logo" alt="Logo" /> */}
            </a>
        </header>
    );
};

export default LayoutHeader;