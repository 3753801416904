import React from 'react';
import { formatNumber } from '../../../../util/utils';

const RevenueAnalysis = ({
  formattedFee,
  handleFeeChange,
  formattedCost,
  handleCostChange,
  currentMemberCount,
  setIsModalOpen,
  customMemberIncrease,
  handleCustomMemberIncreaseChange,
  totalMemberCount,
  projectedRevenue,
  projectedCost
}) => {
  return (
    <div>
      <div className="mb-6 flex flex-wrap items-center justify-center gap-4">
        <label className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">이용료</span>
          <input
            type="text"
            value={formattedFee}
            onChange={handleFeeChange}
            className="p-2 border-2 border-blue-300 rounded-md w-32 text-right focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
          />
        </label>
        <label className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">비용</span>
          <input
            type="text"
            value={formattedCost}
            onChange={handleCostChange}
            className="p-2 border-2 border-green-300 rounded-md w-32 text-right focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
          />
        </label>
        <div className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">현재 회원 수</span>
          <div className="flex items-center">
            <input
              type="text"
              value={formatNumber(currentMemberCount)}
              disabled
              className="p-2 border-2 border-gray-300 rounded-md w-32 text-right bg-gray-100"
            />
            <button
              onClick={() => setIsModalOpen(true)}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              멤버보기
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">예상 회원 증가</span>
          <input
            type="text"
            value={customMemberIncrease}
            onChange={handleCustomMemberIncreaseChange}
            placeholder="자동 계산"
            className="p-2 border-2 border-yellow-300 rounded-md w-32 text-right focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition duration-200"
          />
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">총 예상 회원 수</span>
          <input
            type="text"
            value={formatNumber(totalMemberCount)}
            disabled
            className="p-2 border-2 border-blue-300 rounded-md w-32 text-right bg-blue-50"
          />
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">예상 총 매출</span>
          <input
            type="text"
            value={formatNumber(projectedRevenue)}
            disabled
            className="p-2 border-2 border-purple-300 rounded-md w-40 text-right bg-purple-50"
          />
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-2 text-gray-700 font-medium">예상 총 비용</span>
          <input
            type="text"
            value={formatNumber(projectedCost)}
            disabled
            className="p-2 border-2 border-red-300 rounded-md w-40 text-right bg-red-50"
          />
        </div>
      </div>
    </div>
  );
};

export default RevenueAnalysis;