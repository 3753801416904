import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MarketShareChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis 
        yAxisId="left"
        scale="log" 
        domain={[0.1, 25]} 
        tickFormatter={(value) => `${value.toFixed(1)}%`}
      />
      <YAxis 
        yAxisId="right"
        orientation="right"
        scale="linear"
        domain={[0, 100]}
        tickFormatter={(value) => `${value.toFixed(0)}%`}
      />
      <Tooltip 
        formatter={(value, name, entry) => {
          const formattedValue = `${parseFloat(value).toFixed(2)}%`;
          let label;
          if (entry.dataKey === "marketShare") {
            label = "점유율";
          } else if (entry.dataKey === "cumulativeChurnRate") {
            label = "누적 이탈률";
          } else if (entry.dataKey === "cumulativePaidChurnRate") {
            label = "누적 유료 이탈률";
          }
          return [formattedValue, label];
        }}
      />
      <Legend />
      <Bar yAxisId="left" dataKey="marketShare" fill="#8884d8" name="점유율" />
      <Bar yAxisId="right" dataKey="cumulativeChurnRate" fill="#82ca9d" name="누적 이탈률" />
      <Bar yAxisId="right" dataKey="cumulativePaidChurnRate" fill="#ff7300" name="누적 유료 이탈률" />
    </BarChart>
  </ResponsiveContainer>
);

export default MarketShareChart;