// 분류를 위한 것들은 카테고라이즈에, 계산이나 포맷팅을 위한 것은 유틸에

// 천자리 ㅗ맷팅
export const formatNumber = (value) => {
    return new Intl.NumberFormat('ko-KR').format(value);
  };

// NOTE 기관명 슬라이싱 가공, 기관기호를 제거하는 함수
export function sliceIfValid(organ_nm) {
    // 괄호 안의 11자리 숫자 검사 정규식
    const regex = /\((\d{11})\)$/;
    // 정규식 검사 후 slice
    if (regex.test(organ_nm)) {
        return organ_nm.slice(0, -13);
    } else {
        // 11자리 숫자가 괄호 안에 없으면 원본 그대로 반환하거나 다른 처리
        return organ_nm;
    }
};

// NOTE JSON 문자열을 객체로 파싱
export const safeJsonParse = (jsonString) => {
    // '-' 문자가 포함되어 있으면 빈 객체 반환
    if (jsonString.includes('-')) {
        return {};
    }
    
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error('JSON parse error:', error.message);
        return {};
    }
};

// NOTE 정규식을 사용하여 음수 값을 문자열로 변환
export const preprocessJsonString = (jsonString) => {
    return jsonString.replace(/:\s*(-\d+)/g, ': "$1"');
};

// 설립된 년수 계산 함수
export const calculateEstablishedYears = (startDate) => {
    const startYear = new Date(startDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - startYear;
};