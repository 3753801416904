import React from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Cell } from 'recharts';

const MonthlyGrowthRateChart = ({ data }) => {
  const chartData = data.map((item) => ({
    month: item.month,
    // growthRate: item.growthRate ?? 0, // 성장률은 그냥 없애자
    growthNumber: item.growthNumber ?? 0,
  }));
  
  // const maxGrowthRate = Math.max(...chartData.map(d => d.growthRate)); // 성장률은 그냥 없애자
  // const minGrowthRate = Math.min(...chartData.map(d => d.growthRate)); // 성장률은 그냥 없애자
  const maxGrowthNumber = Math.max(...chartData.map(d => Math.abs(d.growthNumber)));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label">{`날짜: ${label}`}</p>
          {/* <p className="intro" style={{color: '#8884d8'}}>{`성장률: ${payload[1].value.toFixed(2)}%`}</p> */}
          <p className="intro" style={{color: payload[0].value >= 0 ? '#82ca9d' : '#ff7675'}}>{`증감 수: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        {/* <YAxis 
          yAxisId="left"
          domain={[Math.min(0, minGrowthRate - 5), maxGrowthRate + 5]}
          tickFormatter={(value) => `${value > 0 ? '+' : ''}${value.toFixed(2)}%`}
          tick={{ fontSize: 12, fill: '#333' }}
          label={{ value: '성장률 (%)', angle: -90, position: 'insideLeft' }}
        /> */}
        <YAxis 
          yAxisId="right"
          orientation="right"
          domain={[0, maxGrowthNumber]}
          tickFormatter={(value) => value}
          tick={{ fontSize: 12, fill: '#333' }}
          label={{ value: '증감 수', angle: 90, position: 'insideRight' }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar 
          yAxisId="right"
          dataKey="growthNumber" 
          name="증감 수"
          fill="#82ca9d"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.growthNumber >= 0 ? '#82ca9d' : '#ff7675'} />
          ))}
        </Bar>
        {/* <Line
          yAxisId="left"
          type="monotone"
          dataKey="growthRate" 
          name="성장률 (%)"
          stroke="#8884d8"
          strokeWidth={2}
          dot={{ r: 4 }}
        /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default MonthlyGrowthRateChart;