import api from '../api';

export const postAdminAuth = async (password) => {
    try {
        const response = await api.post('/admin-auth', { password });
        if (response.message === '인증 성공') {
            return response;
        } else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        throw new Error(error.response?.data?.message || '인증에 실패했습니다.');
    }
};