import React from 'react';

const Development = () => {
  return (
    <div className="bg-blue-100 p-4 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-2">개발 현황</h3>
      <a 
        href="https://www.notion.so/d3bad734446f4cd4beab225fe21885d0?v=c85fd82fab714b1891ac06170e73856c" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-700 underline hover:text-blue-900"
      >
        개발 현황 노션
      </a>
    </div>
  );
};

export default Development;