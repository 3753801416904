import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import './Searching.css';
import { getSido } from '../../api/get/getSido';
import { getDongri } from '../../api/get/getDongri';

const Searching = ({ onSelectArea }) => {
    const [selectedArea, setSelectedArea] = useState(['서울시']);
    const [selectedType, setSelectedType] = useState('방문요양');
    const [selectedOrder, setSelectedOrder] = useState('점수 높은 순');
    const [history, setHistory] = useState(null);
    const [isDongriLevel, setIsDongriLevel] = useState(false);
    const [sidoCode, setSidoCode] = useState(null);
    const [admCode, setAdmCode] = useState(null);

    const { data: gungus = [], isLoading: gungusLoading, error: gungusError } = useQuery(
        ['gungus', sidoCode],
        () => getSido(sidoCode).then(response => response.data.admVOList.admVOList),
        { enabled: !!sidoCode }
    );

    const { data: dongris = [], isLoading: dongrisLoading, error: dongrisError } = useQuery(
        ['dongris', admCode],
        () => getDongri(admCode).then(response => response.data.admVOList.admVOList),
        { enabled: !!admCode }
    );

    const closeAllModalsExcept = (exceptModal) => {
        const modals = document.querySelectorAll('.modal-search');
        modals.forEach(modal => {
            if (!modal.classList.contains(exceptModal)) {
                modal.classList.remove('active');
            }
        });
    };

    const handleSearchLocationModal = () => {
        const locationModal = document.querySelector('.modal-search.area');
        const isActive = locationModal.classList.contains('active');
        closeAllModalsExcept('area');
        locationModal.classList.toggle('active', !isActive);
    };

    const handleSearchTypeModal = () => {
        const typeModal = document.querySelector('.modal-search.type');
        const isActive = typeModal.classList.contains('active');
        closeAllModalsExcept('type');
        typeModal.classList.toggle('active', !isActive);
    };

    const handleSearchOrderModal = () => {
        const orderModal = document.querySelector('.modal-search.order');
        const isActive = orderModal.classList.contains('active');
        closeAllModalsExcept('order');
        orderModal.classList.toggle('active', !isActive);
    };

    const performSearch = useCallback((area, type, order) => {
        closeAllModalsExcept();
        onSelectArea(area, type, order);
    }, [onSelectArea]);

    const handleAreaClick = useCallback((event) => {
        if (event.target.classList.contains('sido-nm') && event.target.classList.contains('area-value')) {
            const sidocode = event.target.getAttribute('data-sidocode');
            const sidoName = event.target.textContent.trim();
            setHistory(null);
            setSelectedArea([sidoName]);
            setIsDongriLevel(false);
            setSidoCode(sidocode);
            setAdmCode(null);

            // 세종특별자치시 처리
            if (sidoName === '세종특별자치시') {
                setIsDongriLevel(true);
                setSelectedArea([sidoName]);
                setSidoCode(null);
                setAdmCode(null);
                performSearch([sidoName], selectedType, selectedOrder);
                closeAllModalsExcept();
            }
        }
    }, [selectedType, selectedOrder, performSearch, closeAllModalsExcept]);

    const handleGunguClick = useCallback((event) => {
        if (event.target.classList.contains('gungu-nm') && event.target.classList.contains('area-value')) {
            const admcode = event.target.getAttribute('data-admcode');
            const gunguName = event.target.textContent.trim();
            setHistory({ gungus, selectedArea });
            if (gunguName === '전체') {
                setSelectedArea(prevSelectedArea => {
                    const newSelectedArea = [prevSelectedArea[0]];
                    performSearch(newSelectedArea, selectedType, selectedOrder);
                    return newSelectedArea;
                });
            } else {
                setSelectedArea(prevSelectedArea => {
                    const newSelectedArea = [...prevSelectedArea, gunguName];
                    setIsDongriLevel(false);
                    setAdmCode(admcode);
                    return newSelectedArea;
                });
            }
        }
    }, [gungus, selectedArea, selectedType, selectedOrder, performSearch]);

    const handleDongriClick = useCallback((event) => {
        if (event.target.classList.contains('dongri-nm') && event.target.classList.contains('area-value')) {
            const dongriName = event.target.textContent.trim();
            setHistory({ gungus, dongris, selectedArea });
            if (dongriName === '전체') {
                setSelectedArea(prev => {
                    const newArea = prev.slice(0, 2);
                    performSearch(newArea, selectedType, selectedOrder);
                    return newArea;
                });
            } else {
                setSelectedArea(prev => {
                    const newArea = [...prev.slice(0, 2), dongriName];
                    performSearch(newArea, selectedType, selectedOrder);
                    return newArea;
                });
                setIsDongriLevel(true);
            }
        }
    }, [gungus, dongris, selectedType, selectedOrder, performSearch]);

    const handleTypeClick = (event) => {
        if (event.target.classList.contains('type-value')) {
            const type = event.target.textContent.trim();
            setSelectedType(type);
        }
    };

    const handleOrderClick = (event) => {
        if (event.target.classList.contains('order-value')) {
            const order = event.target.textContent.trim();
            setSelectedOrder(order);
        }
    };

    const handleBackClick = useCallback(() => {
        if (history) {
            setSelectedArea(history.selectedArea);
            setIsDongriLevel(false);
            onSelectArea(history.selectedArea, selectedType, selectedOrder);
            if (history.selectedArea.length === 1) {
                setSidoCode(null);
                setAdmCode(null);
            } else if (history.selectedArea.length === 2) {
                setSelectedArea([history.selectedArea[0]]);
                setSidoCode(history.gungus[0]?.admCode.slice(0, 2));
                setAdmCode(null);
            }
            setHistory(null);
        } else {
            setSidoCode(null);
            setAdmCode(null);
        }
    }, [history, selectedType, selectedOrder, onSelectArea]);

    const handleSearchButtonClick = useCallback(() => {
        performSearch(selectedArea, selectedType, selectedOrder);
    }, [selectedArea, selectedType, selectedOrder, performSearch]);

    if (gungusLoading || dongrisLoading) {
        // return <div>Loading...</div>;
    }

    if (gungusError || dongrisError) {
        return <div>Error: {gungusError?.message || dongrisError?.message}</div>;
    }

    const displayArea = selectedArea.length === 1
        ? selectedArea[0]
        : selectedArea.length === 2
        ? `${selectedArea[0]} ${selectedArea[1]}`
        : `${selectedArea[0]} ${selectedArea[1]} ${selectedArea[2]}`;
    
    return (
        <section className="search-wp">
            <div className="search-area">
                <div className="search-type line" onClick={handleSearchLocationModal}>
                    <div className="search-title">지역</div>
                    <div className="search-value area" id="seaching-text">
                        {displayArea || '서울시'}
                    </div>
                </div>
                <div className="search-type line" onClick={handleSearchTypeModal}>
                    <div className="search-title">서비스 종류</div>
                    <div className="search-value category">{selectedType}</div>
                </div>
                <div className="search-type" onClick={handleSearchOrderModal}>
                    <div className="search-title">정렬 순서</div>
                    <div className="search-value score">{selectedOrder}</div>
                </div>
                <div className="search-circle" onClick={handleSearchButtonClick}>
                    <i className="fa-solid fa-magnifying-glass" id="search-btn"></i>
                </div>
            </div>

            <div className="modal-search area">
                {gungus.length > 0 && (
                    <div className="area-value prev" onClick={handleBackClick}><i className="fa-solid fa-chevron-left"></i> 이전</div>
                )}
                
                {gungus.length === 0 && dongris.length === 0 && (
                    <div id="sido-list" className="select-area" onClick={handleAreaClick}>
                        <div className="sido-nm area-value" data-sidocode="11">서울시</div>
                        <div className="sido-nm area-value" data-sidocode="26">부산시</div>
                        <div className="sido-nm area-value" data-sidocode="27">대구시</div>
                        <div className="sido-nm area-value" data-sidocode="28">인천시</div>
                        <div className="sido-nm area-value" data-sidocode="29">광주시</div>
                        <div className="sido-nm area-value" data-sidocode="30">대전시</div>
                        <div className="sido-nm area-value" data-sidocode="31">울산시</div>
                        <div className="sido-nm area-value" data-sidocode="36">세종특별자치시</div>
                        <div className="sido-nm area-value" data-sidocode="41">경기도</div>
                        <div className="sido-nm area-value" data-sidocode="43">충청북도</div>
                        <div className="sido-nm area-value" data-sidocode="44">충청남도</div>
                        <div className="sido-nm area-value" data-sidocode="46">전라남도</div>
                        <div className="sido-nm area-value" data-sidocode="47">경상북도</div>
                        <div className="sido-nm area-value" data-sidocode="48">경상남도</div>
                        <div className="sido-nm area-value" data-sidocode="50">제주특별자치도</div>
                        <div className="sido-nm area-value" data-sidocode="52">전라북도</div>
                    </div>
                )}
                {gungus.length > 0 && dongris.length === 0 && (
                    <div id="gungu-list" className="select-area" onClick={handleGunguClick}>
                        <div className="area-value gungu-nm" onClick={handleSearchButtonClick}>전체</div>
                        {gungus.map(sido => (
                            <div key={sido.admCode} className="gungu-nm area-value" data-admcode={sido.admCode}>
                                {sido.admCodeNm.split(' ')[0] === '경기도' && sido.admCodeNm.split(' ').length > 2
                                    ? `${sido.admCodeNm.split(' ')[1]} ${sido.admCodeNm.split(' ')[2]}`
                                    : sido.admCodeNm.split(' ')[1]
                                }
                            </div>
                        ))}
                    </div>
                )}
                {dongris.length > 0 && (
                    <div id="dongri-list" className="select-area" onClick={handleDongriClick}>
                        <div className="area-value dongri-nm">전체</div>
                        {dongris.map(dongri => (
                            <div key={dongri.admCode} className="dongri-nm area-value">
                                {dongri.admCodeNm.split(' ')[0] === '경기도'
                                    ? dongri.admCodeNm.split(' ')[3] || dongri.admCodeNm.split(' ')[2]
                                    : dongri.admCodeNm.split(' ')[2]
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="modal-search type">
                <div id="type-list" className="select-type" onClick={handleTypeClick}>
                    <div className="type-value">노인요양시설</div>
                    <div className="type-value">방문요양</div>
                    <div className="type-value">방문목욕</div>
                    <div className="type-value">방문간호</div>
                    <div className="type-value">주야간보호</div>
                    <div className="type-value">단기보호</div>
                    <div className="type-value">복지용구</div>
                    <div className="type-value">치매전담</div>
                </div>
            </div>

            <div className="modal-search order">
                <div id="order-list" className="select-order" onClick={handleOrderClick}>
                    <div className="order-value">점수 높은 순</div>
                    <div className="order-value">점수 낮은 순</div>
                </div>
            </div>
        </section>
    );
};

export default Searching;