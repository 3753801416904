import { useState, useEffect } from 'react';

const useScroll = (loadMoreCallback, threshold = 50) => {
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (windowHeight + scrollTop >= documentHeight - threshold) {
        loadMoreCallback();
      }

      const searchWpElement = document.querySelector('.search-wp');

      if (searchWpElement) {
        if (scrollTop > 500) {
          if (scrollTop > prevScrollTop) {
            searchWpElement.style.transform = 'translateY(0)';
          } else {
            searchWpElement.style.transform = 'translateY(-100%)';
          }
          searchWpElement.classList.add('floating');
        } else {
          searchWpElement.style.transform = 'translateY(0)';
          searchWpElement.classList.remove('floating');
        }
      }
      setPrevScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreCallback, prevScrollTop, threshold]);

  return prevScrollTop;
};

export default useScroll;