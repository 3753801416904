import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import OrganDetail from './pages/OrganDetail/OrganDetail';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminAuth from './components/admin/adminauth/AdminAuth';
import { postAdminAuth } from './api/post/postAdminAuth';
import './App.css';

// QueryClient 인스턴스 생성
const queryClient = new QueryClient();

const useTailwindCSS = (isActive) => {
    useEffect(() => {
        const tailwindHref = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
        const link = document.querySelector(`link[href="${tailwindHref}"]`);
        
        if (isActive && !link) {
            const newLink = document.createElement('link');
            newLink.href = tailwindHref;
            newLink.rel = 'stylesheet';
            document.head.appendChild(newLink);
        } else if (!isActive && link) {
            document.head.removeChild(link);
        }
    }, [isActive]);
};

const AppContent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();

    const adminAuthMutation = useMutation(postAdminAuth, {
        onSuccess: (data) => {
            if (data.message === '인증 성공') {
                setIsAuthenticated(true);
            } else {
                alert(data.message || '인증에 실패했습니다.');
            }
        },
        onError: (error) => {
            alert(error.message || '인증에 실패했습니다. 비밀번호를 확인해주세요.');
        }
    });

    const handleLogin = (password) => {
        adminAuthMutation.mutate(password);
    };

    const isAdminRoute = location.pathname.startsWith('/admin');
    useTailwindCSS(isAdminRoute);

    useEffect(() => {
        // 어드민 라우트가 아닐 때 테일윈드 비활성화
        const tailwindLink = document.getElementById('tailwind-css');
        if (!isAdminRoute && tailwindLink) {
            document.head.removeChild(tailwindLink);
        }
    }, [isAdminRoute]);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/organ-detail/:ltcAdminSym" element={<OrganDetail />} />
            <Route path="/login" element={<Login />} />
            <Route 
                path="/admin" 
                element={
                    isAuthenticated ? (
                        <Dashboard />
                    ) : (
                        <AdminAuth onLogin={handleLogin} isLoading={adminAuthMutation.isLoading} />
                    )
                } 
            />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <AppContent />
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;