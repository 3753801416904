import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatNumber } from '../../../../util/utils';

const RevenueChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis 
        yAxisId="left"
        tickFormatter={(value) => `${formatNumber(Math.round(value / 1000000))}M`} 
        style={{ fontSize: '12px' }}
      />
      <YAxis 
        yAxisId="right" 
        orientation="right" 
        tickFormatter={(value) => `${formatNumber(Math.round(value / 1000000))}M`}
        style={{ fontSize: '12px' }}
      />
      <Tooltip 
        formatter={(value, name) => [
          `${formatNumber(value)}원`, 
          name === 'revenue' ? '매출' : '수익'
        ]}
        labelFormatter={(label) => `날짜: ${label}`}
      />
      <Legend formatter={(value) => (value === 'revenue' ? '매출' : '수익')} />
      <Line yAxisId="left" type="monotone" dataKey="revenue" stroke="#82ca9d" name="revenue" />
      <Line yAxisId="right" type="monotone" dataKey="profit" stroke="#8884d8" name="profit" />
    </LineChart>
  </ResponsiveContainer>
);

export default RevenueChart;
