import React from 'react';

const Loading = ({ message = 'Loading...' }) => {
  return (
    <div className="loading-container">
      <div>{message}</div>
    </div>
  );
};

export default Loading;