import React from 'react';

const PaymentManagement = () => {
  return (
    <div className="bg-red-100 p-4 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-2">결제 관리</h3>
      <ul className="list-disc list-inside">
        <li>금일 결제 건수: 150건</li>
        <li>총 결제 금액: 15,000,000원</li>
        <li>미결제 건수: 5건</li>
      </ul>
    </div>
  );
};

export default PaymentManagement;