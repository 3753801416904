import axios from 'axios';
import { requestInterceptor, responseInterceptor, errorInterceptor } from './interceptor';

// 1.기본 설정
const baseURL = 'https://www.yoyangalimi.com/api';
// const baseURL = 'http://localhost:8088/api';

const instance = axios.create({
    baseURL,
    timeout: 5000,
});
 
// // 2.인터셉터 적용, jwt token get 및 set
// instance.interceptors.request.use(requestInterceptor);
// instance.interceptors.response.use(responseInterceptor, errorInterceptor);

const handleApiError = (error) => {
    console.error('API call failed:', error);

    if (error.response) {
        // 서버가 응답을 보낸 경우 (4xx, 5xx 상태 코드 등)
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
    } else if (error.request) {
        // 요청이 만들어졌으나 응답을 받지 못한 경우
        console.error('Request data:', error.request);
    } else {
        // 요청을 만들기 전의 문제 발생 (예: 설정 오류)
        console.error('Error message:', error.message);
    }

    throw error;
};

// 3.api method 실행
const api = {
    get: async (url, config = {}) => {
        try {
            const response = await instance.get(url, config);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    },
    post: async (url, data, config = {}) => {
        try {
            const response = await instance.post(url, data, config);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    },
    put: async (url, data, config = {}) => {
        try {
            const response = await instance.put(url, data, config);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    },
    delete: async (url, config = {}) => {
        try {
            const response = await instance.delete(url, config);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    },
};

export default api;