import React, { useEffect, useState, useCallback } from 'react';
import './Login.css';
import LayoutHeader from '../../components/layout/LayoutHeader';
import Footer from '../../components/layout/LayoutFooter';

const Login = () => {
    return (
        <div className="container">
            <LayoutHeader />

            <main className="content-wp">
                <div className='login-wp'>
                    <div>
                        <p><span>카카오</span>로 로그인</p>
                    </div>
                    <div>
                        <p><span>네이버</span>로 로그인</p>
                    </div>
                    <div>
                        <p><span>이메일</span>로 로그인</p>
                    </div>

                    <div>
                        <p>요양 알리미가 처음이라면</p>
                    </div>

                    <div>
                        <p>회원가입</p>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default Login;
