import React, { useState } from 'react';
import TopNav from '../../components/admin/nav/TopNav';
import SubMenu from '../../components/admin/nav/SubMenu';
import Content from '../../components/admin/content/Content';

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState('운영관리');
  const [activeSubMenu, setActiveSubMenu] = useState('개발');

  return (
    <div className="flex h-screen">
      <TopNav activeMenu={activeMenu} setActiveMenu={setActiveMenu} className="w-1/6" />
      <div className="flex flex-row h-full w-5/6">
        <SubMenu
          activeMenu={activeMenu}
          activeSubMenu={activeSubMenu}
          setActiveSubMenu={setActiveSubMenu}
          className="w-1/6"
        />
        <Content activeMenu={activeMenu} activeSubMenu={activeSubMenu} className="w-5/6" />
      </div>
    </div>
  );
};

export default Dashboard;
