import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getEntireOrganList } from '../../../../api/get/getOrgans';
import { getMember } from '../../../../api/get/getMember';
import { formatNumber } from '../../../util/utils';
import { calculateRevenue, analyzeData, calculateMonthlyGrowthRate } from './utils/calculateSales';
import CumulativeGrowthChart from './charts/CumulativeGrowthChart';
import MarketShareChart from './charts/MarketShareChart';
import RevenueChart from './charts/RevenueChart';
import YearlyRevenueChart from './charts/YearlyRevenueChart';
import MonthlyGrowthRateChart from './charts/MonthlyGrowthRateChart';
import RevenueAnalysis from './charts/RevenueAnalysis';

const SalesGrowth = () => {
  const { data: entireOrganData, isLoading: organLoading } = useQuery('entireOrganList', getEntireOrganList);
  const { data: memberData, isLoading: memberLoading } = useQuery('memberList', getMember);

  const [fee, setFee] = useState(33000);
  const [cost, setCost] = useState(16500);
  const [revenueData, setRevenueData] = useState(null);
  const [yearlyData, setYearlyData] = useState(null);
  const [monthlyGrowthRateData, setMonthlyGrowthRateData] = useState(null);
  const [formattedFee, setFormattedFee] = useState('33,000');
  const [formattedCost, setFormattedCost] = useState('16,500');
  const [customMemberIncrease, setCustomMemberIncrease] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFeeChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    setFee(Number(rawValue));
    setFormattedFee(formatNumber(rawValue));
  };

  const handleCostChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    setCost(Number(rawValue));
    setFormattedCost(formatNumber(rawValue));
  };

  const handleCustomMemberIncreaseChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    setCustomMemberIncrease(rawValue);
  };

  // NOTE 매출계산을 위한 변수들, 컴포넌트에 전달
  const currentDate = new Date();
  const activeMembers = memberData ? memberData.filter(member => new Date(member.mem_expiration_date) >= currentDate) : [];
  const currentMemberCount = activeMembers.length;
  const totalMemberCount = currentMemberCount + (customMemberIncrease ? parseInt(customMemberIncrease) : 0);
  const projectedRevenue = totalMemberCount * fee * 12;
  const projectedCost = totalMemberCount * cost * 12;

  useEffect(() => {
    if (memberData) {
      const { revenueChartData, yearlyData } = calculateRevenue(memberData, fee, cost, customMemberIncrease);
      setRevenueData(revenueChartData);
      setYearlyData(yearlyData);

      const monthlyGrowthData = calculateMonthlyGrowthRate(memberData);
      setMonthlyGrowthRateData(monthlyGrowthData);
    }
  }, [fee, cost, memberData, customMemberIncrease]);

  if (organLoading || memberLoading) {
    return <div>데이터 로딩 중...</div>;
  }

  const analysisResult = analyzeData(entireOrganData, memberData);

  if (!analysisResult) {
    return <div>데이터 분석 중 오류가 발생했습니다.</div>;
  }

  const MemberListModal = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMembers, setFilteredMembers] = useState(memberData || []);
  
    const handleSearch = () => {
      const filtered = memberData ? memberData.filter(member => 
        member.organ_nm.toLowerCase().includes(searchTerm.toLowerCase())
      ) : [];
      setFilteredMembers(filtered);
    };
  
    return isModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg flex flex-col" style={{ height: '80vh' }}>
          <div className="p-4 border-b">
            <h2 className="text-lg font-bold mb-2">회원 리스트</h2>
            <div className="flex">
              <input
                type="text"
                placeholder="이름으로 검색..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-grow px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSearch}
                className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                검색
              </button>
            </div>
          </div>
          <div className="flex-grow overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">이름</th>
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">등록일</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredMembers.map((member, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2 whitespace-nowrap text-sm">{member.organ_nm}</td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm">{new Date(member.mem_register_datetime).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredMembers.length === 0 && (
              <div className="text-center py-4 text-gray-500">검색 결과가 없습니다.</div>
            )}
          </div>
          <div className="border-t p-4">
            <button
              onClick={() => setIsModalOpen(false)}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-yellow-100 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-4 text-gray-800">서비스 현황 분석</h3>
      <ul className="list-disc list-inside mb-6 text-gray-700">
        <li>전체 기관 수: {formatNumber(analysisResult.totalOrganCount)}</li>
        <li>전체 회원 수: {formatNumber(analysisResult.activeMemberCount)}</li>
      </ul>

      <h4 className="text-xl font-semibold mb-3 text-gray-800">누적 증감 비교</h4>
      <CumulativeGrowthChart data={analysisResult.chartData} />

      <h4 className="text-xl font-semibold mb-3 mt-6 text-gray-800">점유율 추이</h4>
      <MarketShareChart data={analysisResult.chartData} />

      <h4 className="text-xl font-semibold mb-3 mt-6 text-gray-800">월별 증감률</h4>
      {monthlyGrowthRateData && <MonthlyGrowthRateChart data={monthlyGrowthRateData} />}

      <h4 className="text-xl font-semibold mb-4 mt-6 text-gray-800">매출 및 수익 분석 (실제 및 예상)</h4>
      <RevenueAnalysis
        formattedFee={formattedFee}
        handleFeeChange={handleFeeChange}
        formattedCost={formattedCost}
        handleCostChange={handleCostChange}
        currentMemberCount={currentMemberCount}
        setIsModalOpen={setIsModalOpen}
        customMemberIncrease={customMemberIncrease}
        handleCustomMemberIncreaseChange={handleCustomMemberIncreaseChange}
        totalMemberCount={totalMemberCount}
        projectedRevenue={projectedRevenue}
        projectedCost={projectedCost}
      />
      {revenueData && <RevenueChart data={revenueData} />}
      {yearlyData && <YearlyRevenueChart data={yearlyData} />}
      <MemberListModal />
    </div>
  );
};

export default SalesGrowth;