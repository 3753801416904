import React from 'react';

const Consulting = () => {
  return (
    <div className="bg-green-100 p-4 rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-2">상담 현황</h3>
      <ul className="list-disc list-inside">
        <li>금주 상담 건수: 25건</li>
        <li>평균 상담 시간: 30분</li>
        <li>고객 만족도: 4.5/5</li>
      </ul>
    </div>
  );
};

export default Consulting;