// 분류를 위한 것들은 카테고라이즈에, 계산이나 포맷팅을 위한 것은 유틸에

// 서비스 명에 따른 adminPttnCd 매핑
export const serviceAdminPttnCdMap = {
    '방문요양': 'C01', '방문목욕': 'C02', '주야간보호': 'C03', '단기보호': 'C04', '방문간호': 'C05', '복지용구': 'C06',
    '재가노인복지시설 방문요양': 'B01', '재가노인복지시설 방문목욕': 'B02', '재가노인복지시설 주야간보호': 'B03', 
    '재가노인복지시설 단기보호': 'B04', '재가노인복지시설 방문간호': 'B05', '재가노인복지시설 복지용구': 'B06',
    '주야간보호내치매전담1실': 'H31', '주야간보호내치매전담2실': 'H32', '주야간보호내치매전담3실': 'H33', 
    '주야간보호내치매전담4실': 'H34', '주야간보호내치매전담5실': 'H35', '주야간보호내치매전담6실': 'H36',
};

// 인원수를 기반으로 시설규모를 계산하는 함수
export const getFacilitySize = (manpower) => {
    if (manpower <= 15) {
        return '소형';
    } else if (manpower <= 45) {
        return '중형';
    } else if (manpower > 45){
        return '대형';
    } else {
        return '확인안됨'
    }
};

// 점수를 기반으로 등급을 계산하는 함수
export const getGrade = (score) => {
    if (score >= 90) {
        return 'A등급';
    } else if (score >= 80) {
        return 'B등급';
    } else if (score >= 70) {
        return 'C등급';
    } else if (score >= 0) {
        return 'D등급';
    } else {
        return '등급 없음';
    }
};