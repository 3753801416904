import React from 'react';
import Development from './development/Development';
import Consulting from './consulting/Consulting';
import SalesGrowth from './salesGrowth/SalesGrowth';
import PaymentManagement from './paymentManagement/PaymentManagement';

const Content = ({ activeMenu, activeSubMenu }) => {
  const renderContent = () => {
    switch (activeSubMenu) {
      case '개발':
        return <Development />;
      case '상담':
        return <Consulting />;
      case '매출분석':
        return <SalesGrowth />;
      case '결제관리':
        return <PaymentManagement />;
      default:
        return <p>선택된 메뉴가 없습니다.</p>;
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">{activeMenu} - {activeSubMenu}</h2>
      {renderContent()}
    </div>
  );
};

export default Content;