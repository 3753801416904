import React from 'react';
import './OrganCard.css';
import icon from '../../assets/images/caremanager/icon.png';
import { sliceIfValid } from '../util/utils';

const OrganCard = ({ member }) => {
    const {
        organ_nm = member.organ_nm,
        start = member.start,
        score = member.score,
        mem_address1 = member.mem_address1,
        // mem_address2 = member.mem_address2,
        // notice = member.notice,
        // mem_biz_contact_num = member.mem_biz_contact_num,
        mem_id = member.mem_id,
        // ltcAdminSym = member.ltcAdminSym,
        services = member.services,
        image_src = member.image_src,
    } = member;

    // NOTE 기관명에서 기관기호 제거
    const slicedOrganNm = sliceIfValid(organ_nm)

    const imageUrl = image_src && image_src !== '/npbs/images/np/contents/sample03.gif'
        ? `https://longtermcare.or.kr/npbs/e/d/101/selectPhotoStreamDocNo.web?atmtFileDocNo=${image_src}`
        : 'https://longtermcare.or.kr/npbs/images/np/contents/sample03.gif';

    const processedServices = services.split(',').map(service => {
        switch (service.trim()) {
            case '재가장기요양기관 방문요양':
                return '방문요양';
            case '재가노인복지시설 방문요양':
                return '방문요양';
            case '재가장기요양기관 방문목욕':
                return '방문목욕';
            case '재가노인복지시설 방문목욕':
                return '방문목욕';
            case '재가장기요양기관 방문간호':
                return '방문간호';
            case '재가노인복지시설 방문간호':
                return '방문간호';
            case '재가장기요양기관 주야간보호':
                return '주야간보호';
            case '재가노인복지시설 주야간보호':
                return '주야간보호';
            case '치매전담형노인요양공동생활가정':
                return '공동생활가정';
            case '치매전담실가형1실':
            case '치매전담실가형2실':
            case '치매전담실가형3실':
            case '치매전담실가형4실':
            case '치매전담실나형1실':
            case '치매전담실나형2실':
            case '치매전담실나형3실':
            case '치매전담실나형4실':
                return '치매전담';
            case '주야간보호내치매전담1실':
            case '주야간보호내치매전담2실':
            case '주야간보호내치매전담3실':
            case '주야간보호내치매전담4실':
                return '치매전담';
            case '노인요양시설(개정법)':
                return '노인요양시설';
            default:
                return service.trim();
        }
    }).join(', ');

    return (
        <div className="card-wp">
            <div className="info-content">
                {mem_id && (
                    <>
                        <div className="cm-icon">
                            <img src={icon} alt="Care Manager Icon" />케어매니저 인증 기관
                        </div>
                    </>
                )}

                <p className="title">{slicedOrganNm}</p>
                <p>{mem_address1}</p>
                
                <div className="label-list">
                    <label className="label-primary-light">{processedServices || '확인불가'}</label>
                    <label className="label-beige">{score ? `${score}점` : '평가전'}</label>
                    <label className="label-gray">{start.split('T')[0].split('-')[0]}년 설립</label>
                </div>
            </div>

            <div className="picture">
                <img src={imageUrl} alt=""></img>
            </div>
        </div>
    );
};

export default OrganCard;