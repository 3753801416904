import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import LayoutHeader from '../../components/layout/LayoutHeader';
import Loading from '../../components/miscellaneous/Loading';
import { getOrgan } from '../../api/get/getOrgan';
import { getMember } from '../../api/get/getMember';
import { getGeo } from '../../api/get/getGeo';
import Calculator from '../../components/miscellaneous/Calculator';
import { sliceIfValid } from '../../components/util/utils';

// 포맷팅 등 공용함수등
import { safeJsonParse, preprocessJsonString, calculateEstablishedYears } from '../../components/util/utils';
import { getFacilitySize, getGrade } from '../../components/util/categorize';

// 네이버 맵
import { initializeNaverMap } from '../../components/util/naverMap';

// 변수들
import { serviceAdminPttnCdMap } from '../../components/util/categorize';

// 디자인 요소들
import './OrganDetail.css';
import { CSSTransition } from 'react-transition-group';
import icon from '../../assets/images/caremanager/icon.png';
import emptyHeart from '../../assets/images/empty_heart.png';

const OrganDetail = () => {
    const { ltcAdminSym } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [organData, setOrganData] = useState({});
    const [responseGeo, setResponseGeo] = useState(null);

    const [openSections, setOpenSections] = useState({});
    const handleClick = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const sections = [
        { title: '서비스 신청 비용이 있나요? 비용이 얼마나 될까요?', content: '재가장기요양 서비스라면 자기부담금이 있는 경우라도 국가에서 85%~94%까지 지원을 받을 수 있어, 적게는 6%만 부담하고 편리하게 집에서 돌봄서비스를 받을 수 있습니다.' },
        { title: '서비스 이용 문의는 어디로 하면 될까요?', content: '아래 전화상담 버튼을 통해 기관과 직접 상담하실 수 있습니다.' },
        { title: '서비스 이용 조건이 있나요?', content: '국가에서 지정한 절차에 따라, 장기요양등급을 받을 경우, 재가장기요양서비스를 이용하실 수 있습니다. 자세한 내용은 아래 전화상담을 통해 확인해주세요!'},
    ];

    // 전체기관
    const { data: entireOrganData, isLoading: organLoading, error: organError } = useQuery(
        ['entireOrganList', ltcAdminSym],
        () => getOrgan(ltcAdminSym)
    );
    // 개별기관
    const { data: memberData, isLoading: memberLoading, error: memberError } = useQuery(
        'memberList', getMember
    );

    // NOTE 케어매니저 멤버와 전체기관 머지
    useEffect(() => {
        if (entireOrganData && memberData) {
            const mergedData = entireOrganData.map(organ => {
                const member = memberData.find(m => m.ltcAdminSym === organ.ltcAdminSym);
                return member ? {
                  ...organ,
                  ...member,
                  mem_address1: organ.mem_address1,
                  start: organ.start !== '-' ? organ.start : member.start,
                  score: organ.score !== '-' ? organ.score : member.score
                } : organ;
            });

            memberData.forEach(member => {
                if (!entireOrganData.find(organ => organ.ltcAdminSym === member.ltcAdminSym)) {
                    mergedData.push(member);
                }
            });

            const uniqueMergedData = Array.from(new Set(mergedData.map(a => a.ltcAdminSym)))
                .map(id => mergedData.find(a => a.ltcAdminSym === id));

            uniqueMergedData.sort((a, b) => (b.mem_id ? 1 : 0) - (a.mem_id ? 1 : 0));
            const detail = uniqueMergedData.find(el => el.ltcAdminSym === ltcAdminSym) || {};

            // 쿼리스트링에서 category 값 가져오기
            const queryParams = new URLSearchParams(location.search);
            const category = queryParams.get('category');

            const manpowerData = detail.manpower ? safeJsonParse(preprocessJsonString(detail.manpower)) : {};
            const prefix = ltcAdminSym.startsWith('3') ? '재가장기요양기관' : ltcAdminSym.startsWith('2') ? '재가노인복지시설' : null;

            // 기관데이터 객체 생성
            setOrganData({
                ...detail,
                manpower: prefix ? (manpowerData[`${prefix} ${category}`]?.total_manpower ?? '-') : '-',
                imageUrl: detail.image_src && detail.image_src !== '/npbs/images/np/contents/sample03.gif'
                    ? `https://longtermcare.or.kr/npbs/e/d/101/selectPhotoStreamDocNo.web?atmtFileDocNo=${detail.image_src}`
                    : 'https://longtermcare.or.kr/npbs/images/np/contents/sample03.gif',
                facilitySize: getFacilitySize(prefix ? (manpowerData[`${prefix} ${category}`]?.total_manpower ?? '-') : '-'),
                grade: getGrade(detail.score ? detail.score : '-'),
                establishedYears: detail.start ? calculateEstablishedYears(detail.start.split('T')[0]) : '-'
            });

            // getGeo 함수 호출
            getGeo({ address: detail.mem_address1, coordinate: '124,36' })
                .then(geoResponse => {
                    if (geoResponse && geoResponse.data && geoResponse.data.addresses && geoResponse.data.addresses.length > 0) {
                        const { x, y } = geoResponse.data.addresses[0];
                        setResponseGeo({ lat: parseFloat(y), lng: parseFloat(x) });
                    }
                })
                .catch(error => console.error('Error fetching geo data:', error));
        }
    }, [entireOrganData, memberData, ltcAdminSym, location.search]);

    // NOTE 기관명에서 기관기호 제거
    const slicedOrganNm = sliceIfValid(organData.organ_nm)

    // NOTE 네이버 맵 그리기
    useEffect(() => {
        if (responseGeo) {
            const { lat, lng } = responseGeo;
            initializeNaverMap('map', lat, lng);
        }
    }, [responseGeo]);

    // NOTE 공단 페이지 열기(사진, 현원 등)
    const openLongtermWindow = (aTab, serviceName) => {
        const trimmedServiceName = serviceName.trim();
        const adminPttnCd = serviceAdminPttnCdMap[trimmedServiceName] || 'C00';
        const url = `https://longtermcare.or.kr/npbs/r/a/201/selectLtcoSrchDetail.web?ltcAdminSym=${ltcAdminSym}&adminPttnCd=${adminPttnCd}&aTab=${aTab}`;
        window.open(url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=700');
    };

    // NOTE 로딩 세트
    if (organLoading || memberLoading) {return <Loading message="" />;}
    if (organError || memberError) {return <Loading message={`데이터를 불러오는 데 실패했습니다: ${organError?.message || memberError?.message}`} />;}

    return (
        <div className="container">
            <LayoutHeader />

            <div className="detail-wp">
                <Calculator></Calculator>

                <div className="detail-card">
                    <div className="detail-img" style={{ position: 'relative' }}>
                        <img src={organData.imageUrl} alt="detail" />
                        <button className="organ-info-detail-button" type="button" onClick={() => openLongtermWindow(18, '')}>사진 더보기</button>
                    </div>

                    <div className="cm-icon">
                        <img src={icon} alt="Care Manager Icon" />케어매니저 인증 기관
                    </div>

                    <div className="detail-header">
                        <p className="title">{slicedOrganNm}</p>

                        <div className="label-list">
                            <label className="label-primary-light">{organData.services}</label>
                            <label className="label-beige">{organData.score}점</label>
                            <label className="label-gray">{organData.start ? organData.start.split('-')[0] : '-'}년 설립</label>
                        </div>
                    </div>

                    <div className="detail-info">
                        <div className="category-wp">
                            <div className="category-bar"></div>
                            <div className="category-title">소개글</div>
                        </div>

                        <div className='organ-info'>
                            안녕하세요!! {slicedOrganNm} 입니다.<br/>최선을 다해 모시겠습니다.
                        </div>
                    </div>

                    <div className="detail-info">
                        <div className="category-wp">
                            <div className="category-bar"></div>
                            <div className="category-title">시설개요</div>
                        </div>

                        <div className="row">
                            <p className="title">보유인력</p>
                            <p className="description">{organData.manpower}명 (총원)</p>

                            <p className="title mmt_1">설립일자</p>
                            <p className="description">
                                {organData.start} ~
                                (설립{organData.establishedYears}년)
                            </p>
                        </div>
                        <div className="row">
                            <p className="title">시설규모</p>
                            <p className="description">{organData.facilitySize}</p>

                            <p className="title mmt_1">평가점수</p>
                            <p className="description">{organData.score} ({organData.grade})</p>
                        </div>
                        <div className="row">
                            <p className="title">추가제공 서비스</p>
                            <p className="description">{organData.services}</p>
                        </div>
                    </div>
                    <div className="detail-info address-info">
                        <div className="category-wp">
                            <div className="category-bar"></div>
                            <div className="category-title">시설위치</div>
                        </div>

                        <div className="row">
                            <p className="description">{organData.mem_address1} {organData.mem_address2}</p>
                        </div>

                        <div className="row">
                            <div className="maps" id="map"></div>
                        </div>
                    </div>

                    <div className="detail-info">
                        <div className="info-offer">
                            <p>정보제공. 해당 시설 정보는 <a href="https://www.longtermcare.or.kr"><b>국민건강보험공단</b>(www.longtermcare.or.kr)</a> 홈페이지의 정보입니다.</p>
                            {/* <p>본 시설의 관계자인 경우 무료로 "요양알리미"의 시설정보를 관리할 수 있습니다.</p> */}
                            {/* <button type="button">시설 관리하기<i className="fa-solid fa-caret-right"></i></button> */}
                        </div>
                    </div>

                    <div className="detail-info last-detail-info">
                        <div className="tab-area">
                            <div>자주하는 질문</div>
                            {/* <div>후기</div> */}
                        </div>

                        <div className="faq-wp">
                            {sections.map((section, index) => (
                                <>
                                    <div className={`question-wp ${openSections[index] ? 'active' : ''}`} key={index} onClick={() => handleClick(index)}>
                                        <i className="fa-brands fa-quora"></i>{section.title}
                                    </div>

                                    <CSSTransition
                                        in={openSections[index]}
                                        timeout={300}
                                        classNames="collapse"
                                        unmountOnExit
                                    >
                                        <div className="answer-wp">
                                            {section.content}
                                        </div>
                                    </CSSTransition>
                                </>
                            ))}
                        </div>
                    </div>

                    <div className="detail-footer">
                        {/* <img src={emptyHeart} className="heart" alt="Like" /> */}
                        <div className="bottom-btn back" onClick={() => navigate(-1)} ><i className="fa-solid fa-caret-left"></i>뒤로가기</div>
                        <div className="bottom-btn contact-number">
                            <a href={`tel:${organData.mem_biz_contact_num}`}>
                                <i className="fa-solid fa-phone"></i>
                                전화 상담
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganDetail;